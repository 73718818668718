<template>
  <LayoutBase>
    <DefaultAppHeader />

    <v-layout class="overflow-inherit!">
      <v-main :class="{ 'pt-30! pb-12!': !noPad }">
        <slot />
      </v-main>
    </v-layout>

    <DefaultAppFooter />
  </LayoutBase>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LayoutBase from "@/components/layout/LayoutBase.vue";
import { DefaultAppHeader, DefaultAppFooter } from "@/components/layout";

export default defineComponent({
  components: {
    LayoutBase,
    DefaultAppHeader,
    DefaultAppFooter,
  },
  setup() {
    const route = useRoute();
    const noPad = computed(() => route.meta.noAppHeaderPadding);

    return {
      noPad,
    };
  },
});
</script>
